import Grid from "@material-ui/core/Grid";
import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import home_test from "assets/img/home_test.svg";
import styles1 from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
import * as moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import HomeCards from "views/Portal/HomeCards.jsx";
import examAxios from "../apis/examServerAxios";
import theme from "../assets/theme";
import extractString from "../common/extractString";
import ExamTypeHeading from "../views/Portal/ExamTypeHeading";
import NoExams from "../views/Portal/NoExams";
import BottomNav from "./BottomNav";
import { ExamTab, ExamTabs } from "./ExamTypeSelectNav";
import Header from "./Header";
import NetworkProgress from "./NetworkProgress";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import { areAllFalse } from "../utils/general.utils/object.utils.js";

const styles = (theme) => ({
  bimage: {
    backgroundSize: "cover",
    height: "90vh",
    backgroundImage: `url("${home_test}")`,
  },
  navigationDiv: {
    marginLeft: "-4%",
  },
  cardGap: {
    // gap: "20px"
  },
  checkWeight: { fontWeight: 500 },
  bgCustom: {
    // backgroundColor: "#EFF2F4",
    minHeight: "100vh",
    // padding: "0 15% 5% 15%",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2% 7% 2%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "20%",
    },
  },
});
const compare = (a, b) => {
  const dateA = a.startDateTime ? new Date(a.startDateTime) : 0;
  const dateB = b.startDateTime ? new Date(b.startDateTime) : 0;

  if (dateA > dateB) {
    return -1;
  }
  if (dateA < dateB) {
    return 1;
  }
  return 0;
};

const statusMap = {
  FINISHED: "Attended",
  EXPIRED: "Absent",
};

class ParentsHome extends React.Component {
  flagid = "NA";
  examStatusMap = {
    live: "STARTED",
    upcoming: "NOT_STARTED",
    previous: "FINISHED",
    expired: "EXPIRED",
  };
  //exam types selection
  examMap = {
    0: "EAMCET_MPC",
    1: "JEE MAINS",
    2: "JEEMAIN_2021",
    3: "JEEMAIN_2022",
    4: "JEE ADVANCED",
    5: "NEET",
    6: "NEET_2021",
    7: "CUSTOM",
    8: "BIOP",
  };
  examStatus = {
    STARTED: [],
    NOT_STARTED: [],
    FINISHED: [],
    EXPIRED: [],
  };
  courseList = [];

  hostname = window.location.hostname;
  constructor(props) {
    super(props);
    this.props = props;
    this.enterExam = this.enterExam.bind(this);
    this.state = {
      selectedExam:
        this.hostname.includes("valleyoak") || this.hostname.includes("trinity")
          ? Number(
              this.courseList.find((key) => this.courseList[key] === "CUSTOM")
            )
          : Number(
              this.courseList.find(
                (key) => this.courseList[key] === "JEE MAINS"
              )
            ),
      networkRequest: false,
      isMockUser: false,
      type:
        this.hostname.includes("valleyoak") || this.hostname.includes("trinity")
          ? "CUSTOM"
          : "JEE MAINS",
      paid: false,
      advertise: window.location.hostname == "onesaz.com",
      Attended: false,
      Absent: false,
    };
  }
  tabSelection = (objectExamTypes, objectExamStatus) => {
    const courses = {};
    Object.keys(objectExamTypes).forEach((key) => {
      courses[objectExamTypes[key]] = 0;
    });
    objectExamStatus.forEach((data) => {
      if (`${data.test_type}` in courses) {
        courses[data.test_type]++;
      }
    });
    const value = Object.keys(courses).filter((key) => courses[key] > 0)[0];
    const selectedTab = Number(
      Object.keys(objectExamTypes).find((key) => objectExamTypes[key] === value)
    );
    return { selectedTab, courses };
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleTypeSelect = (event, selectedExam) => {
    this.setState({ selectedExam });
    this.setState({ type: this.courseList[selectedExam] });
  };
  componentDidMount() {
    const id = store.get("user").studentIds;
    this.flagid = id;
    let body = JSON.stringify({
      student_id: id,
    });
    // const config = {
    //   onUploadProgress: (event) => console.log(event),
    // };
    this.setState({ networkRequest: true });
    console.log(this.props, "from mock");
    examAxios
      .post("/exams/tests", body)
      .then((body) => body.data)
      .then((data) => {
        let exams = data.payload;

        exams.Items.forEach((exam) => {
          exam.time_check = data.time_check;
          // console.log(
          //   moment(data.time_check).isAfter(moment(exam.endDateTime))
          // );
          if (exam.test_status in this.examStatus) {
            if (
              data.time_check &&
              moment(data.time_check).isAfter(moment(exam.endDateTime)) &&
              exam.test_status == "NOT_STARTED"
            ) {
              // this.examStatus['FINISHED'].push(exam);
              this.examStatus["EXPIRED"].push(exam);
            } else this.examStatus[exam.test_status].push(exam);
          }
        });

        this.examStatus["STARTED"].forEach((exam) => {
          let remainingTime =
            Number(exam.test_duration) +
            Number(
              moment(exam.time_started)
                .subtract(moment.now())
                .format("x")
            );
          if (
            window.location.host.includes("sr.onesaz") ||
            window.location.host.includes("excellencia.onesaz") ||
            window.location.host == "onesaz" ||
            window.location.host.includes("localhost")
          ) {
            //  console.log(moment(res.payload.endDateTime).format("x"));
            const endDateTime = Number(moment(exam.endDateTime).format("x"));
            remainingTime = Number(
              moment(endDateTime)
                .subtract(exam.time_check)
                .format("x")
            );
          }
          console.log(exam, "from home", remainingTime);
          if (remainingTime < 1) {
            let body = JSON.stringify({
              student_id: id,
              test_name: exam.test_name,
            });
            examAxios
              .post("/exams/submittest", body)
              .then((val) => {
                this.forceUpdate();
              })
              .catch((err) => {});
          }
        });
        const allTabs = {
          expired: this.tabSelection(this.examMap, this.examStatus.EXPIRED),
          finished: this.tabSelection(this.examMap, this.examStatus.FINISHED),
          started: this.tabSelection(this.examMap, this.examStatus.STARTED),
          upcomingTab: this.tabSelection(
            this.examMap,
            this.examStatus.NOT_STARTED
          ),
        };
        // find which course is having no exam in it
        const allCourses = Object.values(allTabs)
          .map((value) => value.courses)
          .reduce(
            (a, c) => (
              Object.keys(c).forEach((k) => (a[k] = (a[k] || 0) + c[k])), a
            ),
            {}
          );
        Object.entries(allCourses)
          .filter(([, value]) => value !== 0)
          .forEach(([key, value]) => this.courseList.push(key));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        const user = store.get("user");
        const { level, fathersName } = user;
        this.setState({ networkRequest: false });
        const validTabs = {
          expired: this.tabSelection(this.courseList, this.examStatus.EXPIRED),
          finished: this.tabSelection(
            this.courseList,
            this.examStatus.FINISHED
          ),
          started: this.tabSelection(this.courseList, this.examStatus.STARTED),
          upcomingTab: this.tabSelection(
            this.courseList,
            this.examStatus.NOT_STARTED
          ),
        };
        if (validTabs.upcomingTab.selectedTab) {
          this.setState({
            selectedExam: Number(validTabs.upcomingTab.selectedTab),
          });
          this.setState({
            type: this.courseList[validTabs.upcomingTab.selectedTab],
          });
        } else {
          const selectTab = Object.values(validTabs).find(
            (value) => value.selectedTab > -1
          );
          if (selectTab) {
            this.setState({
              selectedExam: Number(selectTab.selectedTab),
            });
            this.setState({
              type: this.courseList[selectTab.selectedTab],
            });
          }
        }
      });
  }
  enterExam(type, exam) {
    const { history } = this.props;
    store.set("examObject", exam);
    switch (type) {
      case "PREVIOUS":
        history.push({
          pathname: `/${exam.test_name}/new-result`,
          search: `?paper=${exam.paper}&test=${exam.testDisplayName}&type=${
            exam.test_type
          }`,
        });
        break;
    }
  }
  render() {
    const { classes } = this.props;
    const { selectedExam, Attended, Absent } = this.state;
    // console.log(classes)
    const obj = {
      previous: Attended,
      absent: Absent,
    };
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          {/* <Header /> */}
          <div
            id="con-hari"
            // className={
            //   this.flagid.includes("mock") ? classes.bimage : classes.bgCustom
            // }
          >
            {!this.state.networkRequest ? (
              <Grid container className={classes.cardGap} justify="flex-start">
                <Grid xs={12} sm={6} style={{ padding: "0 12px" }}>
                  <Grid container direction="row" justifyContent="start">
                    {!this.state.isMockUser && (
                      <Grid
                        xs={12}
                        style={{
                          top: "10px",
                          marginLeft: "-1%",
                        }}
                      >
                        <ExamTabs
                          value={this.state.selectedExam}
                          onChange={this.handleTypeSelect}
                        >
                          {this.courseList.map((label) => {
                            if (label === "JEE MAINS") label = "JEE MAIN";
                            return (
                              <ExamTab
                                label={extractString(label)}
                                key={label}
                              />
                            );
                          })}
                        </ExamTabs>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container direction="row" justify="start">
                    {!this.state.isMockUser && (
                      <Grid
                        xs={12}
                        style={{
                          top: "10px",
                          // marginLeft: "-1%"
                        }}
                      >
                        <FormGroup
                          classes={{
                            root: classes.actionArea,
                          }}
                        >
                          {Object.keys(this.examStatus)
                            .filter((key) => this.examStatus[key].length > 0)
                            .map((el) => (
                              <FormControlLabel
                                key={el}
                                control={
                                  <Checkbox
                                    checked={this.state[statusMap[el]]}
                                    onChange={this.handleChange}
                                    name={`${statusMap[el]}`}
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    className={classes.checkWeight}
                                  >
                                    {statusMap[el]}
                                  </Typography>
                                }
                              />
                            ))}
                        </FormGroup>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {(areAllFalse(obj) || Attended) &&
                  this.examStatus.FINISHED.filter(
                    (data) => data.test_type === this.courseList[selectedExam]
                  ).length > 0 && (
                    <React.Fragment>
                      <Grid
                        container
                        spacing={2}
                        className={classes.cardGap}
                        justifyContent="flex-start"
                      >
                        {this.examStatus.FINISHED.sort(compare).map((exam) => {
                          return (
                            (exam.test_type == this.state.type ||
                              exam.type == this.state.type) && (
                              <Grid
                                key={exam.test_name}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                              >
                                <HomeCards
                                  proceedas={"VIEW"}
                                  exam={exam}
                                  enterexam={() => {
                                    this.enterExam("PREVIOUS", exam);
                                  }}
                                >
                                  {" "}
                                </HomeCards>
                                {/* <PreviousCard exam={exam} enterExam= {()=> {this.enterExam("PREVIOUS",exam)}}>  </PreviousCard> */}
                              </Grid>
                            )
                          );
                        })}
                      </Grid>
                    </React.Fragment>
                  )}
                {(areAllFalse(obj) || Absent) &&
                  this.examStatus.EXPIRED.filter(
                    (data) => data.test_type === this.courseList[selectedExam]
                  ).length > 0 && (
                    <React.Fragment>
                      <Grid
                        container
                        className={classes.cardGap}
                        justifyContent="flex-start"
                      >
                        {this.examStatus.EXPIRED.sort(compare).map((exam) => {
                          return (
                            (exam.test_type == this.state.type ||
                              exam.type == this.state.type) && (
                              <Grid
                                key={exam.test_name}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                              >
                                <HomeCards
                                  proceedas={"EXPIRED"}
                                  exam={exam}
                                  enterexam={() => {
                                    this.enterExam("EXPIRED", exam);
                                  }}
                                >
                                  {" "}
                                </HomeCards>
                                {/* <PreviousCard exam={exam} enterExam= {()=> {this.enterExam("PREVIOUS",exam)}}>  </PreviousCard> */}
                              </Grid>
                            )
                          );
                        })}
                      </Grid>
                    </React.Fragment>
                  )}
                {Object.values(this.examStatus).every(
                  (value) =>
                    value.filter(
                      (data) => data.test_type === this.courseList[selectedExam]
                    ).length === 0
                ) && <NoExams />}
              </Grid>
            ) : (
              <NetworkProgress />
            )}
          </div>
          <BottomNav />
          {/* <HomeCards> </HomeCards> */}
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  withStyles(styles1)
)(ParentsHome);
