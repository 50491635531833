import {
  Grid,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { capitalize } from "lodash";
import React from "react";
const useStyles = makeStyles((theme) => ({
  noBorder: {
    borderBottom: "none",
  },
  tableHeadCell: {
    fontWeight: "bold",
    borderBottom: "none",
    color: "#2A2A2A",
    fontWeight: 700,
    lineHeight: "20px",
    height: "10px",
    border: "none",
    fontFamily: "Quicksand",
  },
  textTitle: {
    maxWidth: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "20px",
    fontSize: "16px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "18px", // Desktop
    },
  },
  testName: {
    fontSize: "12px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px", // Desktop
    },
    fontWeight: 500,
    lineHeight: "20px",
    textAlign: "left",
    fontFamily: "Quicksand",
  },
  verticleLine: {
    fontSize: "20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "22px", // Desktop
    },
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#D4D4D4",
    fontFamily: "Quicksand",
  },
  tableHead: {
    color: "#2A2A2A",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px", // Desktop
    },
    fontWeight: 700,
    lineHeight: "20px",
    height: "10px",
    border: "none",
    fontFamily: "Quicksand",
  },
  tableContent: {
    color: "#737373",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px", // Desktop
    },
    fontWeight: 400,
    lineHeight: "20px",
    height: "10px",
    border: "none",
    fontFamily: "Quicksand",
    textAlign: "left",
  },

  tr: {
    height: "10px",
    color: "#2A2A2A",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px", // Desktop
    },
    fontWeight: 600,
    lineHeight: "20px",
    border: "none",
    height: "10px",
    fontFamily: "Quicksand",
  },
  td: {
    padding: "4px 4px",
    color: "#2A2A2A",
    fontSize: "14px",
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px", // Tablet
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px", // Desktop
    },
    lineHeight: "20px",
    border: "none",
    height: "10px",
    fontFamily: "Quicksand",
  },
}));
const TestMarks = ({ title, result, subjects, program }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <div className={classes.textTitle}>
          <b>
            {title}&emsp;{program ? `Program Type: ${program}` : null}
          </b>
        </div>
      </Grid>

      {typeof result === "string" ? (
        <Typography align="center" color="error">
          Absent
        </Typography>
      ) : (
        <Grid item>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <td className={classes.tableHead}>Subject</td>
                  <td className={classes.tableHead}>Percentage</td>
                  <td className={classes.tableHead}>Marks</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(subjects).map((sub) => (
                  <tr className={classes.noBorder} key={sub}>
                    <td className={classes.tableContent}>{capitalize(sub)}</td>
                    <td className={classes.tableContent}>
                      {result[sub + "Percent"]}
                    </td>
                    <td className={classes.tableContent}>
                      {result[sub + "Total"]
                        ? `${result[sub]}/${result[sub + "Total"]}`
                        : result[sub]}
                    </td>
                  </tr>
                ))}
                {result && result.total && result.Marks && (
                  <tr className={classes.noBorder}>
                    <td className={classes.tableContent}>
                      <b>Total</b>
                    </td>
                    <td className={classes.tableContent}>
                      <b>{result["percentage"]}</b>
                    </td>
                    <td className={classes.tableContent}>
                      <b>
                        {result["Marks"]
                          ? `${result["Marks"]}/${result["total"]}`
                          : result["Marks"]}
                      </b>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </TableContainer>
          {/* <TableContainer>
            <Table size='small' aria-label='simple table'>
              <TableHead>
                <TableRow className={classes.noBorder}>
                  <TableCell className={classes.tableHeadCell}>
                    Subject
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} align='right'>
                    Percentage
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} align='right'>
                    Marks
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(subjects).map(sub => (
                  <TableRow className={classes.noBorder} key={sub}>
                    <TableCell
                      className={classes.noBorder}
                      component='th'
                      scope='row'
                    >
                      {capitalize(sub)}
                    </TableCell>
                    <TableCell className={classes.noBorder} align='right'>
                      {result[sub + 'Percent']}
                    </TableCell>
                    <TableCell className={classes.noBorder} align='right'>
                      {result[sub]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Grid>
      )}
    </Grid>
  );
};

export default TestMarks;
